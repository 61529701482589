.BlogPageCont {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: stretch;
}

.BlogPageContArticles {
  overflow: scroll;
}

.BlogPageContSplash {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: scroll;
}

.LandingPageContAltSplash {
  display: flex;
  width: 100vw;
  height: 100vh;
}