.ErrorPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items:center;
}

.ErrorCode {
  font-family: AvenirNext-UltraLight;
  font-size: 72px;
  color: #AEAEB2;
  letter-spacing: 3.5px;
  text-align: center;
}

.ErrorMessage {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Avenir-Medium;
  font-size: 16px;
  color: #333336;
  letter-spacing: 1.33px;
}