.ScrollSuggestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ScrollSuggestionText {
  font-family: AvenirNext-Regular;
  font-size: 16px;
  color: #AEAEB2;
  letter-spacing: 1.33px;
  text-align: center;
}