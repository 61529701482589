.BlogNoteCont {
  display: flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
}

.BlogNoteContDeac {
  display: flex;
  align-items: center;
  height: 45px;
}

.BlogNoteCont:hover .BlogNote {
  width: 5px;
  height: 5px;
  border-radius: 6px;
  background: #8E8E93;
  border: 3px solid #8E8E93;
}

.BlogNoteCont .BlogNote:hover {
}

.BlogNoteCont:hover .BlogNoteText {
  text-decoration: underline;
}

.BlogNoteCont .BlogNoteText:hover {
}

.BlogNoteText {
  font-family: AvenirNext-Medium;
  font-size: 16px;
  color: #8E8E93;
  letter-spacing: 1.33px;
  text-align: left;
  padding-left: 40px;
  text-decoration: none;
}

.BlogNote {
  width: 5px;
  height: 5px;
  border-radius: 6px;
  background: #FFFFFF;
  border: 3px solid #8E8E93;
}

.BlogNoteDeact {
  width: 5px;
  height: 5px;
  border-radius: 6px;
  background: #6E6E73;
  border: 3px solid #6E6E73;
}

.BlogNoteTextDeact {
  font-family: AvenirNext-DemiBold;
  font-size: 16px;
  color: #6E6E73;
  letter-spacing: 1.33px;
  text-align: left;
  padding-left: 40px;
  text-decoration: none;
}