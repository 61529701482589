.a-determinist-attitu-3xxWxY {
  color: #636366;
  font-family: 'BespokeSerif-Regular', Helvetica;
  font-size: 16px;
  left: 26px;
  letter-spacing: 1.33px;
  line-height: 21px;
  opacity: 0.67;
  position: absolute;
  top: 237px;
  width: 375px;
}

.group {
  height: 293px;
  opacity: 1;
  position: relative;
  width: 552px;
}

.group-2-3xxWxY {
  height: 49px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 179px;
}

.group-2-8tRroD {
  height: 49px;
  left: 187px;
  opacity: 1;
  position: absolute;
  top: 122px;
  width: 179px;
}

.group-2-mRK5Ty {
  height: 49px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 179px;
}

.group-2-xaVhZg {
  height: 49px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 122px;
  width: 179px;
}

.group-3-3xxWxY {
  height: 213px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 392px;
}

.mem-6hxCH5 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.mem-6zYXUm {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.mem-aURGr1 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.mem-tiv-3xxWxY {
  color: #8e8e93;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 36px;
  width: 207px;
}
.mem-tiv-8tRroD {
  color: #ff3b30;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 187px;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 157px;
  width: 207px;
}
.mem-tiv-mRK5Ty {
  color: #ff3b30;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 35px;
  width: 207px;
}
.mem-tiv-xaVhZg {
  color: #ff3b30;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 157px;
  width: 207px;
}
.mem-w53x01 {
  color: #2c2d2e;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.noun-3xxWxY {
  color: #636366;
  font-family: "Avenir-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.33px;
  line-height: 22px;
  opacity: 1;
  position: absolute;
  top: 110px;
  width: 553px;
}
.number-3xxWxY {
  color: #636366;
  font-family: "Avenir-MediumOblique", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 1px;
  letter-spacing: 1.00px;
  line-height: 16px;
  opacity: 1;
  position: absolute;
  top: 182px;
  white-space: nowrap;
  width: 15px;
}
.number-bq1j6S {
  color: #636366;
  font-family: "Avenir-MediumOblique", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 1px;
  letter-spacing: 1.00px;
  line-height: 16px;
  opacity: 1;
  position: absolute;
  top: 239px;
  white-space: nowrap;
  width: 15px;
}

.text-1-6zYXUm {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 93px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.text-2-aURGr1 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 93px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.text-3-6hxCH5 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 93px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.text-4-w53x01 {
  color: #2c2d2e;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 93px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.the-art-of-deconstru-3xxWxY {
  color: #636366;
  font-family: 'BespokeSerif-Regular', Helvetica;
  font-size: 16px;
  left: 26px;
  letter-spacing: 1.33px;
  line-height: 21px;
  opacity: 0.67;
  position: absolute;
  top: 181px;
  width: 375px;
}
.tive-6hxCH5 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.tive-6zYXUm {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.tive-aURGr1 {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.tive-w53x01 {
  color: #2c2d2e;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.component-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
}
.component-wrapper a {
  text-decoration: none;
  display: contents;
  pointer-events: auto;
}
.component-wrapper a *,
.component-wrapper input,
.component-wrapper video,
.component-wrapper iframe {
  pointer-events: auto;
}
.component-wrapper.not-ready,
.component-wrapper.not-ready * {
  visibility: hidden !important;
}
.hidden,
.hidden * {
  visibility: hidden;
  pointer-events: none;
}
.listeners-active,
.listeners-active * {
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}