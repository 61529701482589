.ArticleContent {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.ArticlePageContent {
  width: 100%;
  box-sizing: border-box;
  padding-left: 45px;
  padding-right: 45px;
  max-width: 800px;
}

.ArticlePageTitle {
  font-family: AvenirNext-Regular;
  font-size: 52px;
  color: #AEAEB2;
  letter-spacing: 2.5px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ArticlePageBack {
  font-family: AvenirNext-DemiBold;
  font-size: 16px;
  color: #AEAEB2;
  letter-spacing: 1.33px;
  text-align: center;
  text-decoration: none;
}

.ArticleContentNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  padding-top: 40px;
}

.ArticlePageFlip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ArticleContentNavigationBar {
  max-width: 50%;
}