.BlogArticles {
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.BlogArticlesContainer {
  position: relative;
  max-width: 800px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
}

.BlogPrimeMeridianContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.BlogPrimeMeridianNoteContainer {
  position: relative;
  z-index: 999;
}

.BlogPrimeMeridian {
  background: #8E8E93;
  width: 7px;
  height: 100%;
  border-left: 4px solid white;
  border-top: 22px solid white;
  border-bottom: 22px solid white;
  box-sizing: border-box;
}