.a-determinist-attitu-ZT5fTx {
  color: #636366;
  font-family: 'BespokeSerif-Regular', Helvetica;
  font-size: 16px;
  left: 25px;
  letter-spacing: 1.33px;
  line-height: 21px;
  opacity: 0.67;
  position: absolute;
  top: 0;
  width: 225px;
}
.group-2-PsP2at {
  height: 56px;
  left: 1px;
  opacity: 1;
  position: absolute;
  top: 271px;
  width: 249px;
}
.group-2-Ud0Vwx {
  height: 49px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 179px;
}
.group-3-PsP2at {
  height: 56px;
  left: 1px;
  opacity: 1;
  position: absolute;
  top: 199px;
  width: 260px;
}
.group-3-iXvpXI {
  height: 91px;
  left: 29px;
  opacity: 1;
  position: absolute;
  top: 118px;
  width: 205px;
}
.group-4-iXvpXI {
  height: 327px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 261px;
}
.group-PsP2at {
  height: 92px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 205px;
}
.group-iXvpXI {
  height: 92px;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 205px;
}
.group5 {
  height: 327px;
  opacity: 1;
  position: relative;
  width: 261px;
}
.mem-qBOPXN {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.mem-tiv-EcFoZX {
  color: #ff3b30;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 36px;
  width: 207px;
}
.mem-tiv-HP2jxo {
  color: #8e8e93;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 36px;
  width: 207px;
}
.mem-tiv-Ud0Vwx {
  color: #ff3b30;
  font-family: 'Roboto-Regular', Helvetica;
  font-size: 24px;
  height: 56px;
  left: 0;
  letter-spacing: 2.00px;
  line-height: 28px;
  opacity: 1;
  position: absolute;
  top: 35px;
  width: 207px;
}
.memtive-EcFoZX {
  color: #ff3b30;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.memtive-HP2jxo {
  color: #2c2d2e;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: -1px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.noun-PsP2at {
  color: #636366;
  font-family: "Avenir-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.33px;
  line-height: 22px;
  opacity: 1;
  position: absolute;
  top: 128px;
  width: 238px;
}
.number-FynGv4 {
  color: #636366;
  font-family: "Avenir-MediumOblique", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.00px;
  line-height: 16px;
  opacity: 1;
  position: absolute;
  top: 1px;
  white-space: nowrap;
  width: 15px;
}
.number-ZT5fTx {
  color: #636366;
  font-family: "Avenir-MediumOblique", Helvetica;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.00px;
  line-height: 16px;
  opacity: 1;
  position: absolute;
  top: 2px;
  white-space: nowrap;
  width: 15px;
}
.text-1-qBOPXN {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 93px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
.the-art-of-deconstru-FynGv4 {
  color: #636366;
  font-family: 'BespokeSerif-Regular', Helvetica;
  font-size: 16px;
  left: 25px;
  letter-spacing: 1.33px;
  line-height: 21px;
  opacity: 0.67;
  position: absolute;
  top: 0;
  width: 236px;
}
.tive-qBOPXN {
  color: #ff2d55;
  font-family: "AvenirNext-Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  left: 106px;
  letter-spacing: 3.00px;
  line-height: 49px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.component-wrapper * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
}
.component-wrapper a {
  text-decoration: none;
  display: contents;
  pointer-events: auto;
}
.component-wrapper a *,
.component-wrapper input,
.component-wrapper video,
.component-wrapper iframe {
  pointer-events: auto;
}
.component-wrapper.not-ready,
.component-wrapper.not-ready * {
  visibility: hidden !important;
}
.hidden,
.hidden * {
  visibility: hidden;
  pointer-events: none;
}
.listeners-active,
.listeners-active * {
  pointer-events: auto;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}