.Splash {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.SplashSuggestion {
	position: absolute;
	bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}