.LandingPageCont {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: stretch;
}

.LandingPageContArticles {
  overflow: scroll;
}

.LandingPageContSplash {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.LandingPageContAltSplash {
  display: flex;
  width: 100vw;
  height: 100vh;
}